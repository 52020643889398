<template>
  <div class="chi-main">
    <div class="chi-main__header">
      <div class="chi-main__header-start">
        <div class="chi-main__title">
          <div class="chi-main__title-heading">SSO Exchange</div>
        </div>
      </div>
    </div>
    <div class="chi-main__content">
      <div class="chi-grid">
        <div class="chi-col -w--9">
          <div class="-p--0">
              <div class="chi-alert -info" role="alert" data-cy="cy-sso-oidc-alert">
                  <i class="chi-alert__icon chi-icon icon-circle-info" aria-hidden="true"></i>
                  <div class="chi-alert__content">
                      <p class="chi-alert__text">Processing single-sign-on authorization request.</p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SAML',
  mounted() {

    console.log("I'm here!!!!!!")
    const domain = localStorage.getItem('sase.lumen.com.domain') || 'customer';

    window.location.replace(
        `/api/v1/saml/${this.$route.params.sase_cust_no}/redirect?SAMLRequest=${encodeURIComponent(this.$route.query.SAMLRequest)}&RelayState=${this.$route.query.RelayState}&sase_cust_no=${this.$route.params.sase_cust_no}&domain=${domain}`
    );

  }
}

</script>
